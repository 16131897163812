@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.input {
  text-align: left;
  position: relative;

  &.industry {
    margin-bottom: 40px;
  }

  &.offset-left {
    padding-left: 0;
    @media (min-width: 768px) {
      padding-left: 15px;
    }
  }

  &.offset-right {
    padding-right: 0;
    @media (min-width: 768px) {
      padding-right: 15px;
    }
  }

  &.with-error {
    .input-wrapper {
      padding-bottom: 62px;
    }
  }

  &.without-icon {
    input {
      padding-left: 16px;
    }
  }
}

.input-inline {
  display: inline-block;
}

.input-sup-wrapper {
  margin-bottom: 25px;
  text-align: left;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: 100%;
  }
}

.input-sup-label {
  padding-right: 16px;
  color: rgba(30,56,75,0.5);
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 16px;
}

.input-wrapper {
  position: relative;
  padding-bottom: 22px;

  &.center {
    text-align: center;
  }

  &.jobs__select, &.types__select {
    padding-bottom: 0;
    margin-bottom: 22px;
  }
}

.input-error-message {
  font-size: 12px;
  line-height: 14px;
  color: $main-red;
  position: absolute;
  left: 16px;
  bottom: 0;

  &.image {
    top: 170px;
  }

  &.company {
    top: 80px;
  }

  &.signup-error {
    white-space: nowrap;
  }
}
.input-error-message-form {
  font-size: 12px;
  line-height: 14px;
  color: $main-red;
  bottom: -2px;
  left: 16px;
  padding-bottom: 10px;
  position: absolute;

  &.image {
    top: 170px;
  }

  &.company {
    top: 80px;
  }

  &.signup-error {
    white-space: nowrap;
  }
}

.show-password {
  display: flex;
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 20px;
  transform: translateY(-45%);
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
}

.input-element,
.input-element-nice-password input {
  width: 100%;
  padding: 10px 45px;
  height: 100%;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid map-get($color-list, "grey-50");;
  border-radius: 20px;
  box-sizing: border-box;
  background: $main-white;
  
  &.without-icon {
    padding: 12px 16px; 
  }

  &:focus {
    border: 1px solid map-get($color-list, "purple-100");
  }

  &.PhoneInput {
    padding-top: 11px;
    padding-bottom: 11px;

    &--focus {
      border:1px solid map-get($color-list, "purple-100");
    }

    .PhoneInputInput {
      border: none;
      background: transparent;
      outline: transparent;
    }

    .PhoneInputCountryIcon {
      vertical-align: top;
      line-height: 1;
      box-shadow: none;
    }
  }

  &_overview,
  &_reviewer {
    padding: 12px 16px;
  }

  &:disabled {
    color: $main-grey;
    cursor: not-allowed;

    &.video {
      background-color: map-get($color-list, "gray-20");
    }
  }

  &:focus {
    outline: none;
  }
}

.input-element-nice-password {
  label {
    width: 100%;
  }

  input {
    margin-top: 0
  }

  .input-password__marker{
    .danger { background-color: $main-red };
  }

  .input-password__description {
    li.danger:before { background-color: $main-red };
  }

  .danger  input {
    border-color: $main-red;
  }
} 

.invalid{
  border-color: map-get($color-list, "red-100");

  &:focus {
    border-color: map-get($color-list, "red-100" )
  }
}
